// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getAttractionsEditDetails, getAttractionsCollections, putAttractionsFormData } from '@/services/attractions.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created () {
    this.init()
  },
  mounted () {
  },
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getAttractionsCollections().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[31].options = res.data.collections.categories
          this.formData[0].createDateItem[32].options = res.data.collections.global_labels
          this.formData[0].createDateItem[28].options = res.data.collections.ticket_types
          this.formData[0].createDateItem[14].options = res.data.collections.citys
          this.formData[0].createDateItem[12].options = res.data.collections.levels
          this.formData[0].createDateItem[7].options = res.data.collections.sites
          this.formData[0].createDateItem[1].options = res.data.collections.statuses
          // this.formData[2].createDateItem[0].createDateFirstItem[1].options = res.data.collections.languages
          // this.formData[4].createDateItem[0].createDateFirstItem[9].createDateFirstItem[1].options = res.data.collections.languages
          // this.formData[0].createDateItem[10].options = res.data.models
          // this.formData[0].createDateItem[4].options = res.data.platforms
        }
      })
      getAttractionsEditDetails(this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.modifyData = res.data.site
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      const params = {
        site: obj
      }
      params.site.categories = params.site.categories.join(',')
      Object.keys(params.site).forEach(res => {
        if (typeof params.site[res] === 'boolean') {
          if (params.site[res] === true) params.site[res] = '1'
          if (params.site[res] === false) params.site[res] = '0'
        }
      })
      putAttractionsFormData(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'attractionsList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    removeArray (createData, obj) {
      if (createData[obj.prop][obj.index].id) {
        this.$set(createData[obj.prop][obj.index], obj.mark, true)
      } else {
        createData[obj.prop].splice(obj.index, 1)
      }
    }
  }
}
